.headerContainer {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    text-align: left;
    padding: 12px 24px;
    background-color: #fff;
}

.title {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}