.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.title {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}