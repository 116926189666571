.mainMenu {
	width: 208px; 

	& li {
		text-align: left;
		margin: 0;
		margin-inline: 0;
		margin-block: 0;
		width: 100%;
	}
}

:global(.ant-menu-submenu-title),
:global(.ant-menu-item) {
	margin: 0 !important;
	margin-inline: 0;
	margin-block: 0;
	width: 100% !important;
	border-radius: 0 !important;
	border-right: 3px solid transparent;
}

:global(.ant-menu-item-selected) {
	border-color: rgba(24, 144, 255, 1);

}

:global(.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title) {
	color: rgba(0, 0, 0, 0.88);
}

:global(.ant-menu-light .ant-menu-submenu-selected.ant-menu-submenu-open >.ant-menu-submenu-title) {
	color: rgba(24, 144, 255, 1);
}