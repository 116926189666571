@import './customMediaQueries.css';

:root {
    --colorRedLight: #0030CC;
    --colorRedDark: #0029a2;
    --colorBlackLight: #171717;
    --colorBlackDark: #4C4C4C;
    --colorGrayLight: #FAFAFA;
}

.selected-row {
  background-color: #EFEFEF;
}