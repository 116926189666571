:global(.ant-form-item-explain) {
    text-align: left !important;
    margin-top: 3px;
}

.root {
    margin: 20px 20px;
    @media (min-width: 768px) {
        margin: 20px 40px;
    }
}

.dashedLine {
    border-top: 1px dashed #d2d1d4;
    margin-bottom: 10px;
    width: 100%;
}

.breadcrumb {
    margin-bottom: 20px;
}

.datePicker {
    width: 100%;
}

.label {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.formItem {
    margin-bottom: 10px;
}

.submitButton {
    margin-top: 15px;
    width: 100%;
    font-size: 16px;
}
