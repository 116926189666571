.primaryButton {
    background-color: var(--colorRedLight);
    box-shadow: none;

    font-weight: 600;
    font-size: 12px;
    min-height: 47px;

    &:hover {
        background-color: var(--colorRedDark) !important;
    }
}

.primaryButtonDark {
    background-color: #171717;
    box-shadow: none;

    font-weight: 600;
    font-size: 12px;
    min-height: 47px;

    &:hover {
        background-color: #4C4C4C !important;
    }
}