* {
  font-family: "Roboto", sans-serif;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 65px;
  }
}

h2 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}

body {
  background: #d8d8d8 !important;
}

.ant-layout {
  background: #d8d8d8 !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters,
.ant-table-wrapper .ant-table-tbody >tr >td  {
  min-width: 130px;
}

.ant-select-selector {
  text-align: left;
}

.ant-table-column-title,
.ant-table-thead .ant-table-cell {
  font-weight: bold;
}

.ant-table-cell {
  line-height: 1.2;
}

.ant-table-cell div {
  min-height: 21px;
}

.ant-table-wrapper .ant-table-cell {
  padding-right: 25px;
}
.ant-table-column-sorter.ant-table-column-sorter-full {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell,
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-hover >.ant-table-cell,
.ant-table-cell-row-hover {
  background-color: #daecff !important;
}

.last-frozen-column {
  border-right: 2px solid #d2d1d4;
}

.mainSectionHeader {
  font-size: 20px;
  font-weight: 600;
}