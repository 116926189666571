.selectorHolder {
  position: relative;
}

.selectorDropdown {
  position: absolute;
  right: 0;
  width: 500px;
  top: 40px;
  z-index: 4; 
  background-color: #fff;
  box-shadow: -2px 5px 12px rgba(0, 0, 0, .16);
  padding: 15px;
  border-radius: 4px;
}

.columnsContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.columnsItem {
  text-align: left;
}

.selectorHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #d2d1d4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}