@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;

  & :global(.ant-picker-panels) {
    flex-direction: column !important;
  
    @media (min-width: 768px) {
      flex-direction: row !important;
    }
  }
  
  & :global(.ant-input-sm) {
    padding: 6px 11px;
  }
  
  & :global(.ant-picker-input) {
    & > input {
      font-size: 14px !important;
    }
  }
  
  & :global(.ant-select-selector),
  & :global(.ant-select-lg) {
    min-height: 32px !important;
    font-size: 14px !important;
  }

  & :global(.ant-select-selection-placeholder) {
    text-align: left;
  }
  
  & :global(.ant-picker-outlined),
  & :global(.ant-input-outlined) {
    padding: 4.5px 11px;
    font-size: 14px;
    width: 100%;
  }
  
  & :global(.ant-select-multiple .ant-select-selector),
  & :global(.ant-picker-outlined),
  & :global(.ant-input-outlined) {
    border-radius: 2px;
  }

  & :global(.format-cell) {
    white-space: nowrap;
  }

  & :global(.ant-table-tbody tr:nth-child(even) td) {
    background-color: #f4f4f4;
  }

  & :global(th.ant-table-cell) {
    user-select: none;
  }
}

.dashedLine {
  border-top: 1px dashed #d2d1d4;
  margin-bottom: 10px;
  width: 100%;
}

.datePicker {
  width: 100%;
}

.searchButton {
  /*width: 100%;*/

  font-size: 16px;
}
.rangePickerContainer {
}
.oddRow {
  background-color: var(--colorGrayLight);
}

.searchFormContainer {
  gap: 10px;

  @media (min-width: 992px) {
	  gap: 10px 0;
  }
}

.mobile {
  display: block;

  @media (min-width: 768px) {
	  display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: 768px) {
	display: block;
  }
}

th {
  border-bottom: 1px solid #d2d1d4 !important;
  font-weight: 400;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  
  @media (min-width: 576px) {
    justify-content: flex-end;
    gap: 16px;
  }
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }

  label {
    white-space: nowrap;
    text-align: left;
    flex-shrink: 0;

    @media (min-width: 576px) {
      min-width: 100px;
    }

    @media (min-width: 992px) {
      min-width: 1px;
    }
  }

  :global(.ant-form-item) {
    width: calc(100% - 50px);
    margin-bottom: 0;
  }
 
  /* :global(.ant-form-item-control) {

  } */
}

.sectionDatepickerFilterHolder {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & :global(.ant-picker-outlined) {
    width: auto;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}