.root {
  display: flex;
  gap: 20px;
  
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;

  & button {
    line-height: 1;
    height: 24px;
  }
}

.item {
  text-align: left;
  flex: 1 0 calc((100% - 87px)/2);
  max-width: calc((100% - 87px)/2);

  & :global(.ant-select-dropdown) {
    position: static;
    margin-top: 5px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    height: 266px;
  }

  & label {
    margin-bottom: 5px;
    display: block;
  }

  & :global(.ant-select-dropdown-empty) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}