.lastUpdateRow {
  display: flex;
  gap: 24px;
  flex-direction: column;
  text-align: left;

  @media (min-width: 576px) {
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
  }

  @media (min-width: 1201px) {
	flex-wrap: nowrap;
	align-items: flex-start;
  }
}

.lastUpdate {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: 45%;
  
  @media (min-width: 1024px) {
	align-items: center;
	flex-direction: row;
  }
  
  @media (min-width: 1201px) {
	min-width: 280px;
	flex-basis: auto;
	padding-top: 12px;
  }
}

.lastUpdateTitle {
  font-size: 16px;
  white-space: nowrap;
}

.lastUpdateText {
  font-size: 14px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.45);
}

.lastUpdateItemTitle {
  font-size: 14px;
  line-height: 29px;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
}

.lastUpdateItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  
  @media (min-width: 1440px) {
	width: calc(100% - 280px);
  }
}

.lastUpdateItem {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  background-color: #FAFAFA;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  flex: 1 0 auto;
  padding: 12.5px 16px;
  
  @media (min-width: 1024px) {
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	flex: 1 0 45%;
  }
  
  @media (min-width: 1400px) {
	padding: 12.5px 16px;
	min-width: 250px;
	flex: 1 0 auto;
  }
}

.lastUpdateItemText {
  white-space: nowrap;
  font-weight: 500;
}