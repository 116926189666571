@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;

  & :global(.ant-picker-panels) {
    flex-direction: column !important;
  
    @media (min-width: 768px) {
      flex-direction: row !important;
    }
  }
  
  & :global(.ant-input-sm) {
    padding: 6px 11px;
  }
  
  & :global(.ant-picker-input) {
    & > input {
      font-size: 14px !important;
    }
  }
  
  & :global(.ant-select-selector),
  & :global(.ant-select-lg) {
    min-height: 32px !important;
    font-size: 14px !important;
  }

  & :global(.ant-select-selection-placeholder) {
    text-align: left;
  }
  
  & :global(.ant-picker-outlined),
  & :global(.ant-input-outlined) {
    padding: 4.5px 11px;
    font-size: 14px;
    width: 100%;
  }
  
  & :global(.ant-select-multiple .ant-select-selector),
  & :global(.ant-picker-outlined),
  & :global(.ant-input-outlined) {
    border-radius: 2px;
  }

  & :global(.ant-table-wrapper .ant-table-thead th:hover) {
    background: #f0f0f0;
  }
}


.dashedLine {
  border-top: 1px dashed #d2d1d4;
  margin-bottom: 10px;
  width: 100%;
}

.datePicker {
  width: 100%;
}

.searchButton {
  /*width: 100%;*/

  font-size: 16px;
}
.rangePickerContainer {
}
.oddRow {
  background-color: var(--colorGrayLight);
}

.searchFormContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.mobile {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

th {
  border-bottom: 1px solid #d2d1d4 !important;
  font-weight: 400;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
