.footer {
  text-align: center;
  color: #171717;
  background-color: #ffffff;
  border-top: 1px solid #73737333;
  min-height: 40px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    padding: 10px 40px;
  }
}

.footerInner {
  width: 100%;
}

.rowLeft {
  width: 50%;
  text-align: left;
  align-items: center;
  gap: 14px;
}

.rowRight {
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

.col {
  cursor: pointer;
  margin-bottom: 5px;
}

.help {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.copy {
  color: rgba(0, 0, 0, 0.45);
}
